export default {
  appTitle: "Distributor portal",
  pt: "Portuguese",
  en: "English",
  es: "Spanish",
  pages: {
    approvals: {
      title: "Approvals",
      subtitle: "List",
      list: {
        action: "Action",
        createdAt: "Created at",
        funil: "Funnel number",
        order: "Order number",
      },
    },
    contact: {
      title: "Contact",
      subtitle: "List",
      list: {
        createdAt: "Created at",
        status: "Status",
      },
      view: {
        subtitle: "View",
        message: "Message",
      },
      new: {
        subtitle: "New contact",
      },
      status: {
        waitingFluke: "Waiting for Fluke",
        waitingSeller: "Waiting for seller",
        closed: "Closed",
      },
    },
    distributors: {
      title: "Distributors",
      subtitle: "List",
      contracts: "Contracts",
      companyIdDisclaimer:
        "Register here all CRN related to this Corporate Name. Also include the Main CRN above.",
      new: {
        subtitle: "New distributor",
      },
      edit: {
        subtitle: "Edit distributor",
      },
    },
    inventory: {
      title: "Inventory",
      subtitle: "List",
      history: {
        fieldChange: "Field change",
        fullReplace: "Full replacement",
      },
      view: {
        dataAnalyzed:
          'Data analyzed successfully. Please press the "FINALIZE" button to complete the operation.',
        itensHasUpdate:
          "Items with highlighted in red have updates for values already entered (old values in parentheses).",
        subtitle: "Data view",
        whatDo: "What do you want to do?",
      },
      new: {
        subtitle: "New inventory",
        column: {
          inventoryDate: "Inventory date",
          price: "Purchase price",
          qtd: "Quantity",
        },
        columnsRelation: {
          definition: "Columns Definition",
          inventoryDate: "Inventory Date",
        },
        errors: {
          inDateColumn:
            'The column related to "DATE" has missing values. This data is required.',
          inPartNumberColumn:
            'The column related to "PART NUMBER" has missing values. This data is required.',
          inQuantityColumn:
            'The column related to "QUANTITY" has missing values. This data is required.',
          insufficientNumberOfColumns:
            "Malformatted file or insufficient number of columns to continue the operation.",
          invalidPartNumberColumn:
            'The column related to "PART NUMBER" has invalid values, this data must have a maximum of 8 characters.',
          invalidPartNumberType:
            'The column related to "PART NUMBER" has invalid values, this data must contain only numbers.',
          invalidQuantityColumn:
            'The column related to "QUANTITY" has invalid values, this data must have a maximum of 4 digits.',
          requiredHeadersNotFoud:
            "The required columns defined as standard were not found.",
          dataFail:
            "Something went wrong, please check the intrunctions at the end of the page.",
        },
        message: {
          blankLines:
            "The file may contain blank lines or out of standard above or below the configuration.",
          headerConf:
            "Check if the column headers are defined in the configuration:",
          headerFile: "Check if the file has only one header line",
          whatsHappening: "What might be happening?",
        },
        missingTotals: {
          emptyDates: "Please report the dates and the corresponding totals:",
          missingDates:
            "Please indicate the total value for the following dates:",
          missingPrices:
            "Please indicate the total value for the following dates:",
        },
      },
      missingData: {
        subtitle: "Missing Data",
      },
      setDefault: {
        subtitle: "Data Configuration",
        firstTime:
          "Since this is the first time a file upload is being done, the following items need to be configured: ",
      },
    },
    general: {
      distributor: "Distributor",
      list: {
        number: "Number",
        createdAt: "Created at",
      },
      listFilter: {
        apply: "Apply",
        clear: "Clear",
        date: "Date",
        distributor: "Distributor",
        filters: "Filters",
        selectDist: "Select a distributor",
        selectMonth: "Select a month",
        selectYear: "Select a year",
      },
      status: {
        cancel: "Canceled - Manual processing",
        complete: "Complete",
        draft: "Draft",
        waitingApproval: "Waiting approval",
        waitingCorrection: "Waiting correction",
        waitingProcess: "Waiting processing",
      },
    },
    mkt: {
      searchResult:
        'No matching results for "</strong>%{term}</strong>". Press <kbd> enter</kbd> to create a new one.',
      index: {
        title: "Marketing",
        subtitle: "Home Portal",
      },
      items: {
        subtitle: "List",
        list: {
          createdAt: "Created at",
          remove: "Remove",
          type: "Type",
        },
        tags: {
          subtitle: "Items tags list",
          list: {
            action: "Action",
            tag: "Tag",
          },
        },
      },
      edit: {
        subtitle: "Edit item",
      },
      new: {
        subtitle: "New item",
      },
      newsletter: {
        subtitle: "Newsletter",
        list: {
          active: "Active",
          createdAt: "Created at",
          email: "Email",
        },
      },
      support: {
        subtitle: "Support",
        list: {
          createdAt: "Created at",
          email: "Email",
          name: "Name",
        },
      },
    },
    orders: {
      confAlert:
        "Column configuration has not yet been done. Please relate the columns of the FILE with those of the SYSTEM.",
      subtitle: "List",
      title: "Orders",
      view: {
        data: "Data",
        history: "See order history",
        optionalData: "Optional data",
        subtitle: "View",
      },
      new: {
        data: "Data",
        optionalData: "Optional data",
        products: "Products",
        subtitle: "New order",
        totalValue: "Total value (without taxes)",
        typeFunnel: "Funnel",
        typeNormal: "Normal",
        typeRetail: "Retail",
      },
      history: {
        data: "Data",
        optionalData: "Optional data",
        subtitle: "Order history",
      },
      preview: {
        data: "Data",
        optionalData: "Optional data",
        subtitle: "Preview",
        totalValue: "Total value (without taxes)",
      },
    },
    pos: {
      tableHeaders: {
        br: {
          activityCnae: "Activity/cnae (end user)",
          city: "City (end user)",
          Cnae: "cnae",
          date: "Inventory Date",
          distributorCnpj: "Distributor CNPJ",
          distributorCnpj: "Distributor CNPJ",
          distributorName: "Distributor Name",
          endUser: "Name (end user)",
          id: "ID",
          partNumber: "Part Number / EAN code",
          price: "Reported Gross Unit Selling Price",
          provider: "Provider",
          providerId: "Provider ID",
          quantity: "Quantity",
          shipmentDate: "Shipment Date (D/M/Y)",
          state: "State (end user)",
        },
        laam: {
          branchId: "Branch Code / ID",
          city: "Ship-to City",
          country: "End Cust ISO Country Code",
          currencyCode: "Currency Code",
          distyCountryCode: "Disty ISO Country Code",
          endCountryCode: "Country Name (if not US)",
          endUser: "Ship-to (end user) Customer Name",
          endUserInvoiceDate: "Ship / Invoice Date",
          endUserNumber: "Ship-to Customer Number",
          endUserOrder: "Ship-to Customer Order #",
          endUserVerticalMarket: "Ship-to (end user) Customer Vertical Market",
          extendedResale: "Extended Resale",
          flukeModelNoun: "Fluke Model / Noun",
          invoiceDate: "Invoice Date",
          invoiceNumber: "Invoice Number",
          partNumber: "Fluke Model or UPC Code",
          postalCode: "Ship-to postal / zip code",
          price: "Unit Cost",
          quantity: "Qty Sold",
          resellerName: "Reseller Name",
          sicCode: "SIC Code",
          state: "Ship-to State ( abbrev )",
          unitResale: "Unit Resale",
        },
      },
      title: "POS",
      subtitle: "List",
      columnsInfo: {
        activityCnae: "Active cnae",
        branchId: "Branch ID",
        city: "City",
        cnae: "cnae",
        country: "Country",
        currencyCode: "Currency Code",
        date: "Date",
        distributorCnpj: "Distributor CNPJ",
        distributorName: "Distributor Name",
        distyCountryCode: "Distributor Country Code",
        endCountryCode: "End Country Code",
        endUser: "End User",
        endUserInvoiceDate: "End User Invoice Date",
        endUserNumber: "End User Number",
        endUserOrder: "End User Order",
        endUserVerticalMarket: "End User Vertical Market",
        extendedResale: "Extended resale price",
        flukeModelNoun: "Fluke Model Noum",
        invoiceDate: "Invoice Date",
        invoiceNumber: "Invoice Number",
        partNumber: "Part Number",
        postalCode: "Zip Code",
        price: "Price",
        provider: "Provider",
        providerId: "Provider ID",
        quantity: "Quantity",
        resellerName: "Reseller Name",
        shipmentDate: "Shipment Date (D/M/Y)",
        sicCode: "SIC Code",
        state: "State (end user)",
        totalPice: "Total Value",
        unitResale: "Unit resale price",
        value: "Value",
      },
      new: {
        subtitle: "New POS",
        message: {
          blankLines:
            "The file may contain blank lines or out of standard above or below the configuration.",
          headerConf:
            "Check if the column headers are defined in the configuration:",
          headerFile: "Check if the file has only one header line",
          whatsHappening: "What might be happening?",
        },
        columnsRelation: {
          definition: "Columns Definition",
        },
        error: {
          inCityColumn:
            "The column for 'CITY' has missing value(s), and this data is required.",
          incnaeColumn:
            "The column for 'ACTIVITY/cnae' has missing value(s), and this data is required.",
          inDateColumn:
            "The column for 'DATE' has missing value(s), and this data is required.",
          inDistributorIdColumn:
            "The column for 'DISTRIBUTOR CRN' has missing value(s), and this data is required.",
          inDistributorIdNumber:
            "The column for 'DISTRIBUTOR CRN' has invalid value(s), this data must be a valid CNPJ number.",
          inDistributorNameColumn:
            "The column for 'DISTRIBUTOR NAME' has missing value(s), and this data is required.",
          inPartNumberColumn:
            "The column for 'PART NUMBER' has missing value(s), and this data is required.",
          inProviderColumn:
            "The column for 'PROVIDER' has missing value(s), and this data is required.",
          inQuantityColumn:
            "The column for 'QUANTITY' has missing value(s), and this data is required.",
          inStateColumn:
            "The column for 'STATE' has missing value(s), and this data is required.",
          insufficientNumberOfColumns:
            "Malformatted file or insufficient number of columns to continue operation.",
          invalidDateColumn:
            "The column for 'DATE' has invalid value(s), this data must have the following format: DAY/MONTH/YEAR.",
          invalidPartNumberColumn:
            "The column for 'PART NUMBER' has invalid value(s), this data must have a maximum of 8 characters.",
          invalidPartNumberType:
            "The column for 'PART NUMBER' has invalid value(s), this data must contain only numbers.",
          invalidQuantityColumn:
            "The column for 'QUANTITY' has invalid value(s), this data must have a maximum of 4 digits.",
          requiredHeadersNotFoud:
            "The mandatory columns defined as standard were not found.",
          dataFail:
            "Something went wrong, please check the intrunctions at the end of the page.",
        },
      },
      history: {
        fieldChange: "Field change",
        fullReplace: "Full replacement",
      },
      view: {
        history: "See history of editions",
        subtitle: "Data view",
      },
      preview: {
        dataAnalyzed:
          'Data analyzed successfully. Please press the "FINALIZE" button to complete the operation.',
        hasUpdate:
          "Items with highlighted in red have updates for values already entered (old values in parentheses).",
        whatDo: "What do you want to do?",
        error: {
          activityCnae: "Activity CNAE is required.",
          branchId: "Branch ID is required.",
          city: "City is required.",
          country: "Country is required.",
          currencyCode: "Currency code is required.",
          distributorCnpj:
            "Distributor CNPJ is required and must be a valid CNPJ.",
          distributorName: "Distributor name is required.",
          distyCountryCode: "Distributor country code is required.",
          endCountryCode: "End country code is required.",
          endUser: "End user is required.",
          endUserInvoiceDate: "End user invoice date is required.",
          endUserNumber: "End user number is required.",
          endUserOrder: "End user order is required.",
          endUserVerticalMarket: "End user vertical market is required.",
          extendedResale: "Extended resale is required and must be a number.",
          flukeModelNoun: "Fluke model noun is required.",
          invoiceDate:
            "Invoice Date field is required and has invalid or missing values.",
          invoiceNumber: "Invoice number is required.",
          partNumber: "Part number is required.",
          postalCode: "Postal code is required.",
          price: "Price is required and must be a number.",
          provider: "Provider is required.",
          quantity: "Quantity is required and must be a number",
          resellerName: "Reseller name is required.",
          shipmentDate: "Shipment date is required and must be a valid Date.",
          sicCode: "SIC code is required.",
          state: "State is required.",
          unitResale: "Unit resale is required and must be a number.",
        },
      },
      missingData: {
        dateTotalValue: "Please report the dates and the corresponding totals:",
        informTotalValue:
          "Please indicate the <strong>TOTAL VALUE</strong> for the following dates:",
        subtitle: "Missing Data",
      },
      setDefaults: {
        firstTime:
          "Since this is the first time a file upload is being done, the following items need to be configured: ",
        subtitle: "Data Configuration",
      },
      withoutSales: {
        setPerdiod: "Set period",
        subtitle: "Period without sales",
      },
    },
    login: {
      forgotPassword: "Forgot password?",
      formEmail: "Email",
      formPassword: "Password",
      loginFail: "Incorrect username or password",
      title: "Distributor portal",
    },
    forgot: {
      disclaimer:
        "An email has been sent to you with instructions for password reset.",
      title: "Recover password",
    },
    reset: {
      title: "Reset password",
    },
    users: {
      title: "Users",
      subtitle: "List",
      permissions: {
        approvals: "Approvals",
        contact: "Contact",
        delete: "Delete",
        distributors: "Distributors",
        inventory: "Inventory",
        mkt: "Marketing",
        mktsite: "Marketing Site",
        orders: "Orders",
        pos: "POS",
        settings: "Settings",
        users: "Users",
      },
      list: {
        createdAt: "Created at",
        email: "Email",
      },
      edit: {
        subtitle: "Edit user",
      },
      new: {
        subtitle: "New user",
      },
    },
    setPassword: {
      checkTerms: "I have read and accept the terms of commitment.",
      disclaimer: "This PASSWORD is TEMPORARY.",
      disclaimerCompliance:
        "You need to accept the terof responsibility to use the system.",
      openCompliance: "Open commitment terms",
      termResponsibility:
        "<p> I, as a Fluke distributor and user of the Distributor Portal, confirm that: </p> <ul> <li> I am aware of the provisions of the General Data Protection Law (Law nº 13,709/2018); </li> <li> I agree with the Fluke and Distributor Portal Privacy Policy; </li> <li> I cannot share personal data of Fluke customers and potential customers with third parties without their express authorization; </li> <li> personal data that customers and potential customers shared by me through the Portal have their express consent to the sharing of their data with Fluke; </li> <li> all of the previous items are equally applicable to any third parties, collaborators and partners who make use of portal access credentials provided by me; </li> <li> Failure to comply with any of these items will result in revocation of the distributor's access to the Portal and other applicable administrative measures. </li> </ul>",
      title: "Set password",
      titleCompliance: "EN - Terms of responsibility",
    },
    profile: {
      personalData: "Personal data",
      resetPass: "Reset password",
      subtitleSetPassword: "Change password",
      title: "My account",
    },
    settings: {
      title: "Settings",
      subtitle: {
        activeCnae: "Active/cnae",
        boxAuth: "Box Auth",
        bulkUpload: "Bulk upload",
        countries: "Countries",
        fileHeaders: "File headers",
        jobroles: "Job roles",
        keywords: "Keywords",
        mktContactEmails: "Marketing Contact - Emails from those responsible",
        mktOrdersEmails: "Marketing Orders - Emails from those responsible",
        mktSupportEmails: "Marketing Support - Emails from those responsible",
        paymentMethods: "Payment methods",
        purchaseIntervals: "Purchase intervals - Demonstration",
        verticalActivityRel: "From-To Vertical-Activities",
      },
      new: {
        subtitle: "New payment method",
      },
      country: {
        subtitle: "Countries",
        list: {
          name: "Name",
          createdAt: "Created at",
        },
      },
      payment: {
        subtitle: "Payment methods",
        list: {
          description: "Description",
          createdAt: "Created at",
        },
      },
      fileHeaders: {
        productsInOrders: "Order file",
        inventory: "Inventory file",
        orders: "Orders file",
        pos: "POS file",
      },
      verticalActivityRel: {
        help: {
          keywords:
            "Below you can view the registered keywords and their respective verticals and create a new keyword, if necessary.",
          keywordsNew:
            "Classification is done through partial comparison of words. <br /> Be careful when entering words with few letters or that are generic.<br /><br /> Example:<br /> If you register the keyword “Ci” with “Cement” and its variations in mind for automatic classification in the “Cement” vertical, “Cinema” or any activity that has “Ci” in its composition, it will automatically be classified in the “Cement” vertical.",
          manageActiveCnae:
            "Hello! This is a space to manage the activities/cnaes that are reported by distributors and their respective verticals. These verticals are visualized in the Power BI POS report, pay attention to this to carry out the classifications correctly.<br /> Below you can see activities that were not automatically classified, meaning they must be classified individually. It is also possible to view all registered activities and make changes, if necessary.",
          bulkUpload:
            "You can perform a Bulk Upload of activities and vertical classification. Please note that, if there is a previously classified activity in your new file, the information will be replaced.<br /><br /> Example: “Trade activities in general” was previously classified in the “Trade” vertical, but in this new file it is classified as “Others” - In certain cases, information may be overwritten and this activity will become part of the “Others” vertical.",
        },
        subtitle: {
          activity: "Activities - Vertical Relationship",
          allActivities: "All activities and cnae classified",
          configRelation: "Settings: Activities-Vertical Relationship",
          relationActivities: "Relate activities with verticals",
        },
        message: {
          columnRelation: "Relate the file columns with the database columns",
          verticalConflict:
            "Conflict between verticals. [Old: %{old}, New: %{new} ]",
          recentFileSend:
            "Um arquivo foi recém enviado e seus dados se ainda não estão disponíveis, estarão em alguns minutos.",
          notAbleToClassify:
            "We were unable to classify this term automatically.",
        },
      },
      jobrole: {
        list: {
          role: "Job role",
          createdAt: "Created at",
        },
      },
    },
  },
  labels: {
    action: "Action",
    active: "Active",
    activeCnae: "Active cnae (End User)",
    activeCnaeMenu: "Active / cnae",
    activeCompanyId: "Active CRN (End User)",
    activities: "Activities",
    activityWithoutClassification: "Activity without classification",
    biling: "Billing",
    btnAdd: "Add",
    btnBack: "Back",
    btnBoxAuth: "Auth app",
    btnCancel: "Cancel",
    btnClean: "Clean",
    btnClearForm: "Clear form",
    btnClose: "Close",
    btnConfirmData: "Confirm data",
    btnContinue: "Continue",
    btnDelete: "Delete",
    btnDeleteOrder: "Delete order",
    btnDeletePOS: "Delete POS",
    btnDownload: "Download",
    btnEdit: "Edit",
    btnEvaluate: "Evaluate terms  again",
    btnFileProcess: "Process file",
    btnFinish: "Finish",
    btnGenerateNew: "Generate new",
    btnGeneratePdf: "Generate PDF",
    btnGoHome: "Go Marketing Home",
    btnLogin: "Login",
    btnLogout: "Logout",
    btnManage: "Manage",
    btnNemItem: "New item",
    btnNew: "New",
    btnNewSend: "New send",
    btnNewTicket: "New ticket",
    btnProcessData: "Process data",
    btnRedefine: "Redefine",
    btnRemoveInventory: "Remove inventory",
    btnReport: "Report",
    btnReset: "Reset",
    btnSave: "Save",
    btnSaveDraft: "Save as draft",
    btnSaving: "Saving",
    btnSeeActive: "See all actives",
    btnSelectFile: "Select file",
    btnSend: "Send",
    bulkUpload: "Bulk upload",
    cancel: "Cancel",
    change: "Change",
    city: "City (End User)",
    clientWithdraws: "Client withdraws",
    cnae: "cnae",
    companyId: "CRN",
    contract: "Contract",
    countries: "Countries",
    countryEn: "Country (EN)",
    countryEs: "Country (ES)",
    countryPt: "Country (PT)",
    createdAt: "Created at",
    createEdit: "Create/Edit",
    currencyPattern: "Pattern for monetary values (decimal and thousand separators)",
    data: "Data",
    dataInsert: "Insert date",
    dataProgram: "Scheduled date",
    date: "Date",
    datePattern: "Date pattern",
    description: "Description",
    descriptionEn: "Description (EN)",
    descriptionEs: "Description (ES)",
    descriptionPt: "Description (PT)",
    distributor: "Distributor",
    distributorCompanyId: "Distributor CRN",
    distributorName: "Distributor name",
    editedItem: "This item has been edited",
    end: "End",
    endUser: "End User",
    error: "Error",
    file: "File",
    files: "Files",
    finalCustomer: "Final customer",
    formConfirmPassword: "Confirm password",
    formEmail: "Email",
    formName: "Name",
    formNewPassword: "New password",
    formPassword: "Password",
    funnel: "Funnel",
    hint: "Hint",
    hintSizeSugested: "Suggested size %{size} pixels",
    ignore: "Ignore",
    insertManualProduct: "Insert product manually",
    inventoryDate: "Inventory date",
    keywords: "Keywords",
    language: "Language",
    model: "Model",
    month: "Mounth",
    name: "Name",
    new: "New",
    newLine: "New line",
    no: "No",
    noRelation: "No relation",
    notRequiredLabel: "No required label",
    notSend: "Not sent",
    number: "Number",
    observation: "Observation",
    onStock: "On stock",
    or: "Or",
    order: "Order",
    orderNumber: "Order number *",
    orgSearch: "Organization/Search",
    outStock: "Out of stock",
    partialPayment: "Partial billing",
    partNumber: "Part number/EAN code",
    paymentCondition: "Payment condition",
    periodWithoutSales: "Period without sales",
    permissions: "Permissions",
    personalData: "Personal data",
    price: "Price",
    processFile: "Process file",
    processingFile: "Processing file",
    productLink: "Product link",
    products: "Products",
    purchasePrice: "Purchase price",
    qtd: "Quantity",
    read: "Read",
    redefinePassword: "Redefine password",
    remove: "Remove",
    replace: "Replace",
    role: "Role",
    roleEn: "Role (EN)",
    roleEs: "Role (ES)",
    rolePt: "Role (PT)",
    segment: "Segment",
    select: "Select...",
    selectColumn: "Select a column from file",
    selectCountry: "Select a country",
    selectFile: "Select a file",
    selectMonth: "Pick a month",
    selectVertical: "Select a vertical",
    selectYear: "Select a year",
    sendFile: "Send file",
    sendXls: "Send XLS/XLSX",
    shipCompany: "Shipping company",
    start: "Start",
    state: "State (End User)",
    supplier: "Supplier",
    supplierId: "Supplier ID",
    system: "System",
    terms: "Terms",
    title: "Title",
    totalLockAlertContent: "The file already has all the prices and the total value is calculated automatically.",
    totalLockAlertTitle: "Change not available!",
    totalPriceAlertContent: "Changing this data will automatically change the total value of the document! Do you wish to proceed?",
    totalPriceAlertTitle: "Attention!",
    totalValue: "Total value",
    type: "Type",
    typeShipping: "Shipping type",
    unitPrice: "Gross unit sale price reported",
    unitValue: "Unit value (without taxes)",
    uploading: "Uploading",
    user: "User",
    value: "Value",
    vertical: "Vertical",
    warning: "Warning",
    withoutSales: "Empty period or without sales!",
    yes: "Yes",
    yourPassword: "Your password"
  },
  snackbar: {
    dataAlterSuccess: "Data successfully altered",
    draftAterSuccess: "Draft successfully altered",
    editCountry: "Country successfully edited",
    editJobRole: "Job role successfully edited",
    editPaymentMethod: "Payment method successfully edited",
    fileHeadersInventorySuccess:
      "File headers for inventory successfully saved",
    fileHeadersOrderSuccess: "File headers for orders successfully saved",
    fileHeadersPosSuccess: "File headers for POS successfully saved",
    malFormFile:
      "File is malformatted or has an insufficient number of columns to continue the operation.",
    orderAlterSuccess: "Order successfully altered",
    passwdAlterSuccess: "Password successfully altered",
    posErrorColumn:
      "Please select another value for the column %{column} this value is not value.",
    posNoSellsReportSuccess: "POS successfully inserted",
    posSetDefaultError:
      "Ocorreu um erro! Por favor verifique se os dados foram preenchidos corretamente.",
    saveCountry: "Country successfully saved",
    saveJobRole: "Job role successfully saved",
    saveMktSupportEmail: "Marketing support email successfully saved",
    savePaymentMethod: "Payment method successfully saved",
  },
  alerts: {
    pendency: "There are <strong>%{total}</strong> active pendencies",
    generateNewToken:
      "Do you want to reset this token? Integration with the system will stop working until it is updated in Microsiga.",
    inventoryRemove: "Do you want to remove this data?",
    posInsertValues: "Please insert the correct values.",
    posNoSellsReportAlreadyExists:
      "This date has already been inserted! Do you want to replace it?",
    posPreviewAlreadyExists:
      "This file contains data(s) already registered! Do you want to replace these data?",
    posRemove: "Do you want to remove this data?",
    removeCompanyId: "Do you want to remove this CRN?",
    removeContract: "Do you want to remove this contract?",
    removeTag: "Do you want to remove this tag?",
    successCompanyRemove: "CRN successfully removed!",
    successRemoveContract: "Contract successfully removed!",
    userEmailExists: "Email already registered. Use another email.",
    userRemove: "Do you want to remove this user?",
    userSuccess: "User successfully created!",
    userSuccessEdit: "User successfully edited!",
  },
  menu: {
    approvals: "Approvals",
    contact: "Contact",
    distributors: "Distributors",
    inventory: "Inventory",
    marketing: "Marketing",
    orders: "Orders",
    pos: "POS",
    settings: "Settings",
    users: "Users",
    children: {
      itens: "Items",
      languages: "Languages",
      list: "List",
      new: "New",
      newsletter: "Newsletter",
      portal: "Home Portal",
      suport: "Support",
      tags: "Tags",
    },
  },
  validation: {
    confirmPassword: "Passwords do not match",
    email: "The email is not valid",
    invalidCNPJ: "Please enter a valid CNPJ",
    invalidDate: "Please enter a valid date",
    invalidNumber: "Please enter a valid number",
    posNotRequired:
      'Data not required, if you do not have it, select the option "not related".',
    required: "This field is required",
    validateCnpj: "This field is mandatory and must be a valid CNPJ",
    validateDate: "This field is mandatory and must be a valid date",
    validateNumber: "This field is required and be a number",
    mustBeNumeric: "This field must be numeric",
  },
};
