export default {
  appTitle: "Portal de Distribuidores",
  pt: "Português",
  en: "Inglês",
  es: "Espanhol",
  pages: {
    approvals: {
      title: "Aprovações",
      subtitle: "Lista",
      list: {
        action: "Ação",
        createdAt: "Criado em",
        funil: "Número do Funil",
        order: "Número do Pedido",
      },
    },
    contact: {
      title: "Contato",
      subtitle: "Lista",
      list: {
        createdAt: "Criado em",
        status: "Status",
      },
      view: {
        subtitle: "Ver",
        message: "Mensagem",
      },
      new: {
        subtitle: "Novo contato",
      },
      status: {
        waitingFluke: "Aguardando Fluke",
        waitingSeller: "Aguardando vendedor",
        closed: "Fechado",
      },
    },
    distributors: {
      title: "Distribuidores",
      subtitle: "Lista",
      contracts: "Contratos",
      companyIdDisclaimer:
        "Registre aqui todos os CRNs relacionados a este Nome Corporativo. Inclua também o CRN principal acima.",
      new: {
        subtitle: "Novo distribuidor",
      },
      edit: {
        subtitle: "Editar distribuidor",
      },
    },
    inventory: {
      title: "Inventário",
      subtitle: "Lista",
      history: {
        fieldChange: "Campo alterado",
        fullReplace: "Substituição completa",
      },
      view: {
        dataAnalyzed:
          'Dados analisados com sucesso. Pressione o botão "FINALIZAR" para completar a operação.',
        itensHasUpdate:
          "Itens em destaque em vermelho têm atualizações para valores já inseridos (valores antigos entre parênteses).",
        subtitle: "Visualização de dados",
        whatDo: "O que você deseja fazer?",
      },
      new: {
        subtitle: "Novo inventário",
        column: {
          inventoryDate: "Data do inventário",
          price: "Preço de compra",
          qtd: "Quantidade",
        },
        columnsRelation: {
          definition: "Definição de Colunas",
          inventoryDate: "Data do Inventário",
        },
        errors: {
          inDateColumn:
            'A coluna relacionada à "DATA" possui valores ausentes. Esses dados são necessários.',
          inPartNumberColumn:
            'A coluna relacionada ao "NÚMERO DA PEÇA" possui valores ausentes. Esses dados são necessários.',
          inQuantityColumn:
            'A coluna relacionada à "QUANTIDADE" possui valores ausentes. Esses dados são necessários.',
          insufficientNumberOfColumns:
            "Arquivo malformatado ou número insuficiente de colunas para continuar a operação.",
          invalidPartNumberColumn:
            'A coluna relacionada ao "NÚMERO DA PEÇA" possui valores inválidos, esses dados devem ter no máximo 8 caracteres.',
          invalidPartNumberType:
            'A coluna relacionada ao "NÚMERO DA PEÇA" possui valores inválidos, esses dados devem conter apenas números.',
          invalidQuantityColumn:
            'A coluna relacionada à "QUANTIDADE" possui valores inválidos, esses dados devem ter no máximo 4 dígitos.',
          requiredHeadersNotFoud:
            "As colunas obrigatórias definidas como padrão não foram encontradas.",
          dataFail:
            "Houve uma falha desconhecida, por favor, verifique as intruções no final da página.",
        },
        message: {
          blankLines:
            "O arquivo pode conter linhas em branco ou fora do padrão acima ou abaixo da configuração.",
          headerConf:
            "Verifique se os cabeçalhos das colunas estão definidos na configuração:",
          headerFile:
            "Verifique se o arquivo possui apenas uma linha de cabeçalho",
          whatsHappening: "O que pode estar acontecendo?",
        },
        missingTotals: {
          emptyDates: "Informe as datas e os totais correspondentes:",
          missingDates: "Indique o valor total para as seguintes datas:",
          missingPrices: "Indique o valor total para as seguintes datas:",
        },
      },
      missingData: {
        subtitle: "Dados Ausentes",
      },
      setDefault: {
        subtitle: "Configuração de Dados",
        firstTime:
          "Como esta é a primeira vez que o upload de arquivo está sendo feito, os seguintes itens precisam ser configurados:",
      },
    },
    general: {
      distributor: "Distribuidor",
      list: {
        number: "Número",
        createdAt: "Criado em",
      },
      listFilter: {
        apply: "Aplicar",
        clear: "Limpar",
        date: "Data",
        distributor: "Distribuidor",
        filters: "Filtros",
        selectDist: "Selecione um distribuidor",
        selectMonth: "Selecione um mês",
        selectYear: "Selecione um ano",
      },
      status: {
        cancel: "Cancelado - Processamento manual",
        complete: "Completo",
        draft: "Rascunho",
        waitingApproval: "Aguardando aprovação",
        waitingCorrection: "Aguardando correção",
        waitingProcess: "Aguardando processamento",
      },
    },
    mkt: {
      searchResult:
        'Nenhum resultado correspondente para "</strong>%{term}</strong>". Pressione <kbd> enter</kbd> para criar um novo.',
      index: {
        title: "Marketing",
        subtitle: "Home do Portal",
      },
      items: {
        subtitle: "Lista",
        list: {
          createdAt: "Criado em",
          remove: "Remover",
          type: "Tipo",
        },
        tags: {
          subtitle: "Lista de tags de itens",
          list: {
            action: "Ação",
            tag: "Tag",
          },
        },
      },
      edit: {
        subtitle: "Editar item",
      },
      new: {
        subtitle: "Novo item",
      },
      newsletter: {
        subtitle: "Newsletter",
        list: {
          active: "Ativo",
          createdAt: "Criado em",
          email: "Email",
        },
      },
      support: {
        subtitle: "Suporte",
        list: {
          createdAt: "Criado em",
          email: "Email",
          name: "Nome",
        },
      },
    },
    orders: {
      confAlert:
        "A configuração da coluna ainda não foi feita. Relacione as colunas do ARQUIVO com as do SISTEMA.",
      subtitle: "Lista",
      title: "Pedidos",
      view: {
        data: "Data",
        history: "Ver histórico do pedido",
        optionalData: "Dados opcionais",
        subtitle: "Visualizar",
      },
      new: {
        data: "Data",
        optionalData: "Dados opcionais",
        products: "Produtos",
        subtitle: "Novo pedido",
        totalValue: "Valor total (sem impostos)",
        typeFunnel: "Funil",
        typeNormal: "Normal",
        typeRetail: "Varejo",
      },
      history: {
        data: "Data",
        optionalData: "Dados opcionais",
        subtitle: "Histórico do pedido",
      },
      preview: {
        data: "Data",
        optionalData: "Dados opcionais",
        subtitle: "Visualização",
        totalValue: "Valor total (sem impostos)",
      },
    },
    pos: {
      tableHeaders: {
        br: {
          activityCnae: "Atividade/cnae (usuário final)",
          city: "Cidade (usuário final)",
          Cnae: "cnae",
          date: "Data do Inventário",
          distributorCnpj: "CNPJ do Distribuidor",
          distributorCnpj: "CNPJ do Distribuidor",
          distributorName: "Nome do Distribuidor",
          endUser: "Nome (usuário final)",
          id: "ID",
          partNumber: "Part Number / EAN code",
          price: "Preço unitário de venda bruto reportado",
          provider: "Fornecedor",
          providerId: "ID do fornecedor",
          quantity: "Quantidade",
          shipmentDate: "Data de Remessa",
          state: "Estado (usuário final)",
        },
        laam: {
          branchId: "Código da Filial / ID",
          city: "Cidade de Envio",
          country: "Código ISO do País de Destino",
          currencyCode: "Código da Moeda",
          distyCountryCode: "Código ISO do País do Distribuidor",
          endCountryCode: "Nome do País (se não for EUA)",
          endUser: "Nome do Cliente (destino final)",
          endUserInvoiceDate: "Data de Envio/Fatura",
          endUserNumber: "Número do Cliente (destino final)",
          endUserOrder: "Número do Pedido do Cliente (destino final)",
          endUserVerticalMarket: "Mercado Vertical do Cliente (destino final)",
          extendedResale: "Revenda Estendida",
          flukeModelNoun: "Fluke Model / Noun",
          invoiceDate: "Data da Fatura",
          invoiceNumber: "Número da Fatura",
          partNumber: "Modelo Fluke ou Código UPC",
          postalCode: "Código Postal / CEP de Destino",
          price: "Custo Unitário",
          quantity: "Quantidade Vendida",
          resellerName: "Nome do Revendedor",
          sicCode: "Código SIC",
          state: "Estado de Destino (abreviado)",
          unitResale: "Revenda Unitária",
        },
      },
      title: "POS",
      subtitle: "Lista",
      columnsInfo: {
        activityCnae: "Atividade/cnae",
        branchId: "ID da filial",
        city: "Cidade",
        cnae: "cnae",
        country: "País",
        currencyCode: "Código de moeda",
        date: "Data",
        distributorCnpj: "CNPJ do Distribuidor",
        distributorName: "Nome do Distribuidor",
        distyCountryCode: "Código do país do distribuidor",
        endCountryCode: "Código do país do usuário final",
        endUser: "Usuário final",
        endUserInvoiceDate: "Data da fatura do usuário final",
        endUserNumber: "Número do usuário final",
        endUserOrder: "Pedido do usuário final",
        endUserVerticalMarket: "Mercado vertical do usuário final",
        extendedResale: "Preço de revenda estendido",
        flukeModelNoun: "Fluke Model Noum",
        invoiceDate: "Data da fatura",
        invoiceNumber: "Número da fatura",
        partNumber: "Número da peça",
        postalCode: "Código postal",
        price: "Preço",
        provider: "Fornecedor",
        providerId: "ID do fornecedor",
        quantity: "Quantidade",
        resellerName: "Nome do revendedor",
        shipmentDate: "Data de envio",
        sicCode: "Código SIC",
        state: "Estado (Usuário Final)",
        totalPice: "Preço total",
        unitResale: "Preço de revenda unitário",
        value: "Valor",
      },
      new: {
        subtitle: "Novo POS",
        message: {
          blankLines:
            "O arquivo pode conter linhas em branco ou fora do padrão acima ou abaixo da configuração.",
          headerConf:
            "Verifique se os cabeçalhos das colunas estão definidos na configuração:",
          headerFile:
            "Verifique se o arquivo possui apenas uma linha de cabeçalho",
          whatsHappening: "O que pode estar acontecendo?",
        },
        columnsRelation: {
          definition: "Definição de Colunas",
        },
        error: {
          inCityColumn:
            "A coluna para 'CIDADE' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          incnaeColumn:
            "A coluna para 'ATIVIDADE/cnae' possui valor(es) auscente(s), e esses dados são obrigatórios.",
          inDateColumn:
            "A coluna para 'DATA' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inDistributorIdColumn:
            "A coluna para 'CRN DO DISTRIBUIDOR' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inDistributorIdNumber:
            "A coluna para 'CRN DO DISTRIBUIDOR' possui valor(es) inválido(s), esses dados devem ser um número de CNPJ válido.",
          inDistributorNameColumn:
            "A coluna para 'NOME DO DISTRIBUIDOR' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inPartNumberColumn:
            "A coluna para 'NÚMERO DA PEÇA' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inProviderColumn:
            "A coluna para 'FORNECEDOR' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inQuantityColumn:
            "A coluna para 'QUANTIDADE' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          inStateColumn:
            "A coluna para 'ESTADO' possui valor(es) ausente(s), e esses dados são obrigatórios.",
          insufficientNumberOfColumns:
            "Arquivo malformatado ou número insuficiente de colunas para continuar a operação.",
          invalidDateColumn:
            "A coluna para 'DATA' possui valor(es) inválido(s), esses dados devem ter o seguinte formato: DIA/MÊS/ANO.",
          invalidPartNumberColumn:
            "A coluna para 'NÚMERO DA PEÇA' possui valor(es) inválido(s), esses dados devem ter no máximo 8 caracteres.",
          invalidPartNumberType:
            "A coluna para 'NÚMERO DA PEÇA' possui valor(es) inválido(s), esses dados devem conter apenas números.",
          invalidQuantityColumn:
            "A coluna para 'QUANTIDADE' possui valor(es) inválido(s), esses dados devem ter no máximo 4 dígitos.",
          requiredHeadersNotFoud:
            "As colunas obrigatórias definidas como padrão não foram encontradas.",
          dataFail:
            "Houve uma falha desconhecida, por favor, verifique as intruções no final da página.",
        },
      },
      history: {
        fieldChange: "Campo alterado",
        fullReplace: "Substituição completa",
      },
      view: {
        history: "Ver histórico de edições",
        subtitle: "Visualização de dados",
      },
      preview: {
        dataAnalyzed:
          'Dados analisados com sucesso. Pressione o botão "FINALIZAR" para completar a operação.',
        hasUpdate:
          "Itens destacados em vermelho possuem atualizações para valores já inseridos (valores antigos entre parênteses).",
        whatDo: "O que você deseja fazer?",
        error: {
          activityCnae: "O campo CNAE de Atividade é obrigatório.",
          branchId: "O campo ID da Filial é obrigatório.",
          city: "O campo Cidade é obrigatório.",
          country: "O campo País é obrigatório.",
          currencyCode: "O campo Código da Moeda é obrigatório.",
          distributorCnpj:
            "O campo CNPJ do Distribuidor é obrigatório e deve ser um CNPJ válido.",
          distributorName: "O campo Nome do Distribuidor é obrigatório.",
          distyCountryCode:
            "O campo Código do País do Distribuidor é obrigatório.",
          endCountryCode: "O campo Código do País Final é obrigatório.",
          endUser: "O campo Usuário Final é obrigatório.",
          endUserInvoiceDate:
            "O campo Data da Fatura do Usuário Final é obrigatório.",
          endUserNumber: "O campo Número do Usuário Final é obrigatório.",
          endUserOrder: "O campo Pedido do Usuário Final é obrigatório.",
          endUserVerticalMarket:
            "O campo Mercado Vertical do Usuário Final é obrigatório.",
          extendedResale:
            "O campo Revenda Estendida é obrigatório e deve ser um número.",
          flukeModelNoun: "O campo Nome do Modelo Fluke é obrigatório.",
          invoiceDate:
            "O campo Data da Fatura é obrigatório e possui valores inválidos ou ausentes.",
          invoiceNumber: "O campo Número da Fatura é obrigatório.",
          partNumber: "O campo Número da Peça é obrigatório.",
          postalCode: "O campo Código Postal é obrigatório.",
          price: "O campo Preço é obrigatório e deve ser um número.",
          provider: "O campo Provedor é obrigatório.",
          quantity: "O campo Quantidade é obrigatório e deve ser um número.",
          resellerName: "O campo Nome do Revendedor é obrigatório.",
          shipmentDate:
            "O campo Data de Envio é obrigatório e deve ser uma data válida.",
          sicCode: "O campo Código SIC é obrigatório.",
          state: "O campo Estado é obrigatório.",
          unitResale:
            "O campo Revenda Unitária é obrigatório e deve ser um número.",
        },
      },
      missingData: {
        dateTotalValue: "Informe as datas e os totais correspondentes:",
        informTotalValue:
          "Indique o <strong>VALOR TOTAL</strong> para as seguintes datas:",
        subtitle: "Dados Ausentes",
      },
      setDefaults: {
        firstTime:
          "Como esta é a primeira vez que o upload de arquivo está sendo feito, os seguintes itens precisam ser configurados:",
        subtitle: "Configuração de Dados",
      },
      withoutSales: {
        setPerdiod: "Definir período",
        subtitle: "Período sem vendas",
      },
    },
    login: {
      forgotPassword: "Esqueceu a senha?",
      formEmail: "Email",
      formPassword: "Senha",
      loginFail: "Nome de usuário ou senha incorretos",
      title: "Portal de Distribuidores",
    },
    forgot: {
      disclaimer:
        "Um email foi enviado para você com instruções para redefinição de senha.",
      title: "Recuperar senha",
    },
    reset: {
      title: "Redefinir senha",
    },
    users: {
      title: "Usuários",
      subtitle: "Lista",
      permissions: {
        boxAuth: "Box Auth",
        approvals: "Aprovações",
        contact: "Contato",
        delete: "Excluir",
        distributors: "Distribuidores",
        inventory: "Inventário",
        mkt: "Marketing",
        mktsite: "Site de Marketing",
        orders: "Pedidos",
        pos: "POS",
        settings: "Configurações",
        users: "Usuários",
      },
      list: {
        createdAt: "Criado em",
        email: "Email",
      },
      edit: {
        subtitle: "Editar usuário",
      },
      new: {
        subtitle: "Novo usuário",
      },
    },
    setPassword: {
      checkTerms: "Li e aceito os termos de compromisso.",
      disclaimer: "Esta SENHA é TEMPORÁRIA.",
      disclaimerCompliance:
        "Você precisa aceitar os termos de responsabilidade para usar o sistema.",
      openCompliance: "Abrir termos de compromisso",
      termResponsibility:
        "<p> Eu, como distribuidor Fluke e usuário do Portal de Distribuidores, confirmo que: </p> <ul> <li> Estou ciente das disposições da Lei Geral de Proteção de Dados (Lei nº 13.709/2018); </li> <li> Concordo com a Política de Privacidade da Fluke e do Portal de Distribuidores; </li> <li> Não posso compartilhar dados pessoais de clientes e potenciais clientes da Fluke com terceiros sem sua autorização expressa; </li> <li> dados pessoais que clientes e potenciais clientes compartilhados por mim através do Portal têm seu consentimento expresso para o compartilhamento de seus dados com a Fluke; </li> <li> todos os itens anteriores são igualmente aplicáveis a terceiros, colaboradores e parceiros que utilizam as credenciais de acesso ao portal fornecidas por mim; </li> <li> O não cumprimento de qualquer um desses itens resultará na revogação do acesso do distribuidor ao Portal e outras medidas administrativas aplicáveis. </li> </ul>",
      title: "Definir senha",
      titleCompliance: "EN - Termos de responsabilidade",
    },
    profile: {
      personalData: "Dados pessoais",
      resetPass: "Redefinir senha",
      subtitleSetPassword: "Mudar senha",
      title: "Minha conta",
    },
    settings: {
      title: "Configurações",
      subtitle: {
        activeCnae: "cnae Ativo",
        boxAuth: "Box Auth",
        bulkUpload: "Upload em massa",
        countries: "Países",
        fileHeaders: "Cabeçalhos de arquivos",
        jobroles: "Cargos",
        keywords: "Palavras-chave",
        mktContactEmails: "Contato de Marketing - Emails dos responsáveis",
        mktOrdersEmails: "Pedidos de Marketing - Emails dos responsáveis",
        mktSupportEmails: "Suporte de Marketing - Emails dos responsáveis",
        paymentMethods: "Métodos de pagamento",
        purchaseIntervals: "Intervalos de compra",
        verticalActivityRel: "De-Para Atividades-Verticais",
      },
      new: {
        subtitle: "Novo método de pagamento",
      },
      country: {
        subtitle: "Países",
        list: {
          name: "Nome",
          createdAt: "Criado em",
        },
      },
      payment: {
        subtitle: "Métodos de pagamento",
        list: {
          description: "Descrição",
          createdAt: "Criado em",
        },
      },
      fileHeaders: {
        productsInOrders: "Arquivo de Pedidos",
        orders: "Arquivo de pedidos",
        inventory: "Arquivo de Estoque",
        pos: "Arquivo POS",
      },
      verticalActivityRel: {
        help: {
          keywords:
            "Abaixo você pode visualizar as palavras- chave cadastradas e suas respectivas verticais e criar uma nova palavra- chave, se necessário.",
          keywordsNew:
            "A classificação é feita por meio de comparação parcial de palavras. <br /> Tenha cuidado ao inserir palavras com poucas letras ou que sejam genéricas.<br /><br /> Exemplo:<br /> Se você cadastrar a palavra-chave “Ci” tendo em mente a palavra “Cimento” e suas variações, “Cinema” ou qualquer atividade que tenha “Ci” em sua composição, será automaticamente classificada na vertical “Cement”.",
          manageActiveCnae:
            "Olá! Este é um espaço para gerenciar as atividades/cnaes que são reportadas pelos distribuidores e suas respectivas verticais. Essas verticais são visualizadas no relatório do Power BI POS, preste atenção nisso para realizar as classificações corretamente.<br><br> Abaixo você confere as atividades que não foram classificadas automaticamente, ou seja, devem ser classificadas individualmente e de forma manual. Também é possível visualizar todas as atividades cadastradas e fazer alterações, se necessário.",
          bulkUpload:
            "Você pode realizar um upload em massa de atividades e classificação vertical. Observe que, caso exista uma atividade anteriormente classificada em seu novo arquivo, as informações serão substituídas.<br /><br /> Exemplo: “Atividades comerciais em geral” foi classificada anteriormente na vertical “Comércio”, mas em este novo arquivo é classificado como “Outros” - em determinados casos pode ocorrer uma sobrescrição de informação e essa atividade passará a fazer parte da vertical “Outros”.",
        },
        subtitle: {
          activity: "Atividades - Relacionamento Vertical",
          allActivities: "Todas as atividades e classificadas cnae",
          configRelation: "Configurações: Relacionamento Atividades-Vertical",
          relationActivities: "Relacione atividades com verticais",
        },
        message: {
          columnRelation: "Relacione as colunas do arquivo com as do sistema.",
          verticalConflict:
            "Conflito entre verticais. [Antigo: %{old}, Novo: %{new} ]",
          recentFileSend:
            "Um arquivo foi recém-enviado e seus dados ainda não estão disponíveis, exclusivos em alguns minutos.",
          notAbleToClassify:
            "Não foi possível classificar este termo automaticamente.",
        },
      },
      jobrole: {
        list: {
          role: "Cargo",
          createdAt: "Criado em",
        },
      },
    },
  },
  labels: {
    action: "Ação",
    active: "Ativo",
    activeCnae: "cnae Ativo (Usuário Final)",
    activeCnaeMenu: "Ativo / cnae",
    activeCompanyId: "CRN Ativo (Usuário Final)",
    activities: "Atividades",
    activityWithoutClassification: "Atividade sem classificação",
    biling: "Faturamento",
    btnAdd: "Adicionar",
    btnBack: "Voltar",
    btnBoxAuth: "Autenticar aplicativo",
    btnCancel: "Cancelar",
    btnClean: "Limpar",
    btnClearForm: "Limpar formulário",
    btnClose: "Fechar",
    btnConfirmData: "Confirmar dados",
    btnContinue: "Continuar",
    btnDelete: "Excluir",
    btnDeleteOrder: "Excluir pedido",
    btnDeletePOS: "Excluir POS",
    btnDownload: "Baixar",
    btnEdit: "Editar",
    btnEvaluate: "Avaliar termos novamente",
    btnFileProcess: "Processar arquivo",
    btnFinish: "Finalizar",
    btnGenerateNew: "Gerar novo",
    btnGeneratePdf: "Gerar PDF",
    btnGoHome: "Ir para Home Marketing",
    btnLogin: "Entrar",
    btnLogout: "Sair",
    btnManage: "Gerenciar",
    btnNemItem: "Novo item",
    btnNew: "Novo",
    btnNewSend: "Novo envio",
    btnNewTicket: "Novo ticket",
    btnProcessData: "Processar dados",
    btnRedefine: "Redefinir",
    btnRemoveInventory: "Remover inventário",
    btnReport: "Reportar",
    btnReset: "Redefinir",
    btnSave: "Salvar",
    btnSaveDraft: "Salvar como rascunho",
    btnSaving: "Salvando",
    btnSeeActive: "Ver todos os ativos",
    btnSelectFile: "Selecionar arquivo",
    btnSend: "Enviar",
    bulkUpload: "Upload em massa",
    cancel: "Cancelar",
    change: "Alterar",
    city: "Cidade (Usuário Final)",
    clientWithdraws: "Retiradas do cliente",
    cnae: "cnae",
    companyId: "CRN",
    contract: "Contrato",
    countries: "Países",
    countryEn: "País (EN)",
    countryEs: "País (ES)",
    countryPt: "País (PT)",
    createdAt: "Criado em",
    createEdit: "Criar/Editar",
    currencyPattern:
      "Padrão para valores monetários (separadores decimais e de milhar)",
    data: "Dados",
    dataInsert: "Inserir data",
    dataProgram: "Data programada",
    date: "Data",
    datePattern: "Padrão de data",
    description: "Descrição",
    descriptionEn: "Descrição (EN)",
    descriptionEs: "Descrição (ES)",
    descriptionPt: "Descrição (PT)",
    distributor: "Distribuidor",
    distributorCompanyId: "CRN do Distribuidor",
    distributorName: "Nome do Distribuidor",
    editedItem: "Item editado",
    end: "Fim",
    endUser: "Usuário Final",
    error: "Erro",
    file: "Arquivo",
    files: "Arquivos",
    finalCustomer: "Cliente final",
    formConfirmPassword: "Confirmar senha",
    formEmail: "Email",
    formName: "Nome",
    formNewPassword: "Nova senha",
    formPassword: "Senha",
    funnel: "Funil",
    hint: "Dica",
    hintSizeSugested: "Tamanho sugerido %{size} pixels",
    ignore: "Ignorar",
    insertManualProduct: "Inserir produto manualmente",
    inventoryDate: "Data do inventário",
    keywords: "Palavras-chave",
    language: "Idioma",
    model: "Modelo",
    month: "Mês",
    name: "Nome",
    new: "Novo",
    newLine: "Nova linha",
    no: "Não",
    noRelation: "Não relacionado",
    notRequiredLabel: "Não obrigatório",
    notSend: "Não enviado",
    number: "Número",
    observation: "Observação",
    onStock: "Em estoque",
    or: "Ou",
    order: "Pedido",
    orderNumber: "Número do pedido *",
    orgSearch: "Organização/Pesquisa",
    outStock: "Fora de estoque",
    partNumber: "Part number/EAN code",
    partialPayment: "Faturamento parcial",
    paymentCondition: "Condição de pagamento",
    periodWithoutSales: "Período sem vendas",
    permissions: "Permissões",
    personalData: "Dados pessoais",
    price: "Preço",
    processFile: "Processar arquivo",
    processingFile: "Processando arquivo",
    productLink: "Link do produto",
    products: "Produtos",
    purchasePrice: "Preço de compra",
    qtd: "Quantidade",
    read: "Ler",
    redefinePassword: "Redefinir senha",
    remove: "Remover",
    replace: "Substituir",
    role: "Função",
    roleEn: "Função (EN)",
    roleEs: "Função (ES)",
    rolePt: "Função (PT)",
    segment: "Segmento",
    select: "Selecionar...",
    selectColumn: "Selecionar uma coluna do arquivo",
    selectCountry: "Selecionar um país",
    selectFile: "Selecionar um arquivo",
    selectMonth: "Escolher um mês",
    selectVertical: "Selecionar um vertical",
    selectYear: "Selecionar um ano",
    sendFile: "Enviar arquivo",
    sendXls: "Enviar XLS/XLSX",
    shipCompany: "Empresa de envio",
    start: "Início",
    state: "Estado (Usuário Final)",
    supplier: "Fornecedor",
    supplierId: "ID do Fornecedor",
    system: "Sistema",
    terms: "Termos",
    title: "Título",
    totalLockAlertContent: "O arquivo já possui todos os preço e o valor total é calculado automaticamente.",
    totalLockAlertTitle: "Alteração não disponivel!",
    totalPriceAlertContent: "A alteração desse dado vai alterar automaticamente o valor total do documento! Você deseja prosseguir?",
    totalPriceAlertTitle: "Atenção!",
    totalValue: "Valor total",
    type: "Tipo",
    typeShipping: "Tipo de envio",
    unitPrice: "Preço de venda unitário bruto reportado",
    unitValue: "Valor unitário (sem impostos)",
    uploading: "Enviando",
    user: "Usuário",
    value: "Valor",
    vertical: "Vertical",
    warning: "Aviso",
    withoutSales: "Período vazio ou sem vendas!",
    yes: "Sim",
    yuorPassword: "Sua senha",
  },
  snackbar: {
    fileHeadersOrderSuccess:
      "Cabeçalhos de arquivo para pedidos salvos com sucesso",
    fileHeadersInventorySuccess:
      "Cabeçalhos de arquivo para inventário salvos com sucesso",
    fileHeadersPosSuccess: "Cabeçalhos de arquivo para POS salvos com sucesso",
    posErrorColumn:
      "Por favor, selecione outro valor para a coluna %{column}, este valor não é válido.",
    posSetDefaultError:
      "Ocorreu um erro! Por favor, verifique se os dados foram preenchidos corretamente.",
    posNoSellsReportSuccess: "POS inserido com sucesso",
    dataAlterSuccess: "Dados alterados com sucesso",
    draftAterSuccess: "Rascunho alterado com sucesso",
    editCountry: "País editado com sucesso",
    editJobRole: "Cargo editado com sucesso",
    editPaymentMethod: "Método de pagamento editado com sucesso",
    malFormFile:
      "O arquivo está mal formatado ou tem um número insuficiente de colunas para continuar a operação.",
    orderAlterSuccess: "Pedido alterado com sucesso",
    passwdAlterSuccess: "Senha alterada com sucesso",
    saveCountry: "País salvo com sucesso",
    saveJobRole: "Cargo salvo com sucesso",
    saveMktSupportEmail: "E-mail de suporte de marketing salvo com sucesso",
    savePaymentMethod: "Método de pagamento salvo com sucesso",
  },
  alerts: {
    pendency: "Existem <strong>%{total}</strong> pendências ativas",
    generateNewToken:
      "Deseja redefinir este token? A integração com o sistema irá parar de funcionar até que seja atualizado no Microsiga.",
    inventoryRemove: "Deseja remover esses dados?",
    posInsertValues: "Por favor, insira os valores corretos.",
    posNoSellsReportAlreadyExists:
      "Esta data já foi inserida! Deseja substituí-la?",
    posPreviewAlreadyExists:
      "Este arquivo contém dados já registrados! Deseja substituir esses dados?",
    posRemove: "Deseja remover esses dados?",
    removeCompanyId: "Deseja remover este CRN?",
    removeContract: "Deseja remover este contrato?",
    removeTag: "Deseja remover esta tag?",
    successCompanyRemove: "CRN removido com sucesso!",
    successRemoveContract: "Contrato removido com sucesso!",
    userEmailExists: "E-mail já registrado. Use outro e-mail.",
    userRemove: "Deseja remover este usuário?",
    userSuccess: "Usuário criado com sucesso!",
    userSuccessEdit: "Usuário editado com sucesso!",
  },
  menu: {
    approvals: "Aprovações",
    contact: "Contato",
    distributors: "Distribuidores",
    inventory: "Inventário",
    marketing: "Marketing",
    orders: "Pedidos",
    pos: "POS",
    settings: "Configurações",
    users: "Usuários",
    children: {
      itens: "Itens",
      languages: "Idiomas",
      list: "Lista",
      new: "Novo",
      newsletter: "Boletim informativo",
      portal: "Página inicial do Portal",
      suport: "Suporte",
      tags: "Tags",
    },
  },
  validation: {
    confirmPassword: "As senhas não coincidem",
    email: "O email não é válido",
    invalidCNPJ: "Por favor, insira um CNPJ válido",
    invalidDate: "Por favor, insira uma data válida",
    invalidNumber: "Por favor, insira um número válido",
    posNotRequired:
      'Dados não obrigatórios, se não os tiver, selecione a opção "não relacionado".',
    required: "Este campo é obrigatório",
    validateCnpj: "Este campo é obrigatório e deve ser um CNPJ válido",
    validateDate: "Este campo é obrigatório e deve ser uma data válida",
    validateNumber: "Este campo é obrigatório e deve ser um número válido",
    mustBeNumeric: "Este campo deve ser numérico",
  },
};
