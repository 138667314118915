export default {
  appTitle: "Portal de distribuidores",
  pt: "Portugués",
  en: "Inglés",
  es: "Español",
  pages: {
    approvals: {
      title: "Aprobaciones",
      subtitle: "Lista",
      list: {
        action: "Acción",
        createdAt: "Creado en",
        funil: "Número de embudo",
        order: "Número de orden",
      },
    },
    contact: {
      title: "Contacto",
      subtitle: "Lista",
      list: {
        createdAt: "Creado en",
        status: "Estado",
      },
      view: {
        subtitle: "Ver",
        message: "Mensaje",
      },
      new: {
        subtitle: "Nuevo contacto",
      },
      status: {
        waitingFluke: "Esperando por Fluke",
        waitingSeller: "Esperando por vendedor",
        closed: "Cerrado",
      },
    },
    distributors: {
      title: "Distribuidores",
      subtitle: "Lista",
      contracts: "Contratos",
      companyIdDisclaimer:
        "Registre aquí todos los CRN relacionados con este Nombre Corporativo. También incluya el CRN Principal arriba.",
      new: {
        subtitle: "Nuevo distribuidor",
      },
      edit: {
        subtitle: "Editar distribuidor",
      },
    },
    inventory: {
      title: "Inventario",
      subtitle: "Lista",
      history: {
        fieldChange: "Cambio de campo",
        fullReplace: "Reemplazo completo",
      },
      view: {
        dataAnalyzed:
          'Datos analizados exitosamente. Por favor, presione el botón "FINALIZAR" para completar la operación.',
        itensHasUpdate:
          "Los elementos destacados en rojo tienen actualizaciones para valores ya ingresados (valores antiguos entre paréntesis).",
        subtitle: "Vista de datos",
        whatDo: "¿Qué desea hacer?",
      },
      new: {
        subtitle: "Nuevo inventario",
        column: {
          inventoryDate: "Fecha de inventario",
          price: "Precio de compra",
          qtd: "Cantidad",
        },
        columnsRelation: {
          definition: "Definición de columnas",
          inventoryDate: "Fecha de inventario",
        },
        errors: {
          inDateColumn:
            'La columna relacionada con "FECHA" tiene valores faltantes. Estos datos son obligatorios.',
          inPartNumberColumn:
            'La columna relacionada con "NÚMERO DE PARTE" tiene valores faltantes. Estos datos son obligatorios.',
          inQuantityColumn:
            'La columna relacionada con "CANTIDAD" tiene valores faltantes. Estos datos son obligatorios.',
          insufficientNumberOfColumns:
            "Archivo mal formateado o número insuficiente de columnas para continuar con la operación.",
          invalidPartNumberColumn:
            'La columna relacionada con "NÚMERO DE PARTE" tiene valores inválidos, estos datos deben tener como máximo 8 caracteres.',
          invalidPartNumberType:
            'La columna relacionada con "NÚMERO DE PARTE" tiene valores inválidos, estos datos deben contener solo números.',
          invalidQuantityColumn:
            'La columna relacionada con "CANTIDAD" tiene valores inválidos, estos datos deben tener como máximo 4 dígitos.',
          requiredHeadersNotFoud:
            "Las columnas requeridas definidas como estándar no se encontraron.",
          dataFail:
            "Hubo una falla desconocida, consulte las instrucciones al final de la página.",
        },
        message: {
          blankLines:
            "El archivo puede contener líneas en blanco o fuera del estándar arriba o debajo de la configuración.",
          headerConf:
            "Verifique si los encabezados de columna están definidos en la configuración:",
          headerFile:
            "Verifique si el archivo tiene solo una línea de encabezado",
          whatsHappening: "¿Qué podría estar sucediendo?",
        },
        missingTotals: {
          emptyDates:
            "Por favor, reporte las fechas y los totales correspondientes:",
          missingDates:
            "Por favor, indique el valor total para las siguientes fechas:",
          missingPrices:
            "Por favor, indique el valor total para las siguientes fechas:",
        },
      },
      missingData: {
        subtitle: "Datos faltantes",
      },
      setDefault: {
        subtitle: "Configuración de datos",
        firstTime:
          "Dado que esta es la primera vez que se realiza una carga de archivo, los siguientes elementos deben configurarse: ",
      },
    },
    general: {
      distributor: "Distribuidor",
      list: {
        number: "Número",
        createdAt: "Creado en",
      },
      listFilter: {
        apply: "Aplicar",
        clear: "Limpiar",
        date: "Fecha",
        distributor: "Distribuidor",
        filters: "Filtros",
        selectDist: "Seleccione un distribuidor",
        selectMonth: "Seleccione un mes",
        selectYear: "Seleccione un año",
      },
      status: {
        cancel: "Cancelado - Procesamiento manual",
        complete: "Completo",
        draft: "Borrador",
        waitingApproval: "Esperando aprobación",
        waitingCorrection: "Esperando corrección",
        waitingProcess: "Esperando procesamiento",
      },
    },
    mkt: {
      searchResult:
        'No hay resultados coincidentes para "</strong>%{term}</strong>". Presione <kbd> enter</kbd> para crear uno nuevo.',
      index: {
        title: "Marketing",
        subtitle: "Portal de inicio",
      },
      items: {
        subtitle: "Lista",
        list: {
          createdAt: "Creado en",
          remove: "Eliminar",
          type: "Tipo",
        },
        tags: {
          subtitle: "Lista de etiquetas de elementos",
          list: {
            action: "Acción",
            tag: "Etiqueta",
          },
        },
      },
      edit: {
        subtitle: "Editar elemento",
      },
      new: {
        subtitle: "Nuevo elemento",
      },
      newsletter: {
        subtitle: "Boletín",
        list: {
          active: "Activo",
          createdAt: "Creado en",
          email: "Email",
        },
      },
      support: {
        subtitle: "Soporte",
        list: {
          createdAt: "Creado en",
          email: "Email",
          name: "Nombre",
        },
      },
    },
    orders: {
      confAlert:
        "La configuración de columnas aún no se ha realizado. Relacione las columnas del ARCHIVO con las del SISTEMA.",
      subtitle: "Lista",
      title: "Pedidos",
      view: {
        data: "Datos",
        history: "Ver historial del pedido",
        optionalData: "Datos opcionales",
        subtitle: "Vista",
      },
      new: {
        data: "Datos",
        optionalData: "Datos opcionales",
        products: "Productos",
        subtitle: "Nuevo pedido",
        totalValue: "Valor total (sin impuestos)",
        typeFunnel: "Embudo",
        typeNormal: "Normal",
        typeRetail: "Minorista",
      },
      history: {
        data: "Datos",
        optionalData: "Datos opcionales",
        subtitle: "Historial del pedido",
      },
      preview: {
        data: "Datos",
        optionalData: "Datos opcionales",
        subtitle: "Vista previa",
        totalValue: "Valor total (sin impuestos)",
      },
    },
    pos: {
      tableHeaders: {
        br: {
          activityCnae: "Actividad/cnae (usuario final)",
          city: "Ciudad (usuario final)",
          Cnae: "cnae",
          date: "Fecha del Inventario",
          distributorCnpj: "CNPJ del Distribuidor",
          distributorCnpj: "CNPJ del Distribuidor",
          distributorName: "Nombre del Distribuidor",
          endUser: "Nombre (usuario final)",
          id: "ID",
          partNumber: "Part Number / EAN code",
          price: "Precio unitario de venta bruto reportado",
          provider: "Proveedor",
          providerId: "ID del proveedor",
          quantity: "Cantidad",
          shipmentDate: "Fecha de Envío",
          state: "Estado (usuario final)",
        },
        laam: {
          branchId: "Código de Sucursal / ID",
          city: "Ciudad de Envío",
          country: "Código ISO del País de Destino",
          currencyCode: "Código de Moneda",
          distyCountryCode: "Código ISO del País del Distribuidor",
          endCountryCode: "Nombre del País (si no es EE. UU.)",
          endUser: "Nombre del Cliente (destinatario final)",
          endUserInvoiceDate: "Fecha de Envío/Factura",
          endUserNumber: "Número del Cliente (destinatario final)",
          endUserOrder: "Número de Pedido del Cliente (destinatario final)",
          endUserVerticalMarket:
            "Mercado Vertical del Cliente (destinatario final)",
          extendedResale: "Reventa Extendida",
          flukeModelNoun: "Fluke Model / Noun",
          invoiceDate: "Fecha de Factura",
          invoiceNumber: "Número de Factura",
          partNumber: "Modelo Fluke o Código UPC",
          postalCode: "Código Postal / ZIP de Destino",
          price: "Costo Unitario",
          quantity: "Cantidad Vendida",
          resellerName: "Nombre del Revendedor",
          sicCode: "Código SIC",
          state: "Estado de Envío (abreviado)",
          unitResale: "Reventa Unitaria",
        },
      },
      title: "POS",
      subtitle: "Lista",
      columnsInfo: {
        activityCnae: "Actividad/cnae",
        branchId: "ID de la sucursal",
        city: "Ciudad",
        cnae: "cnae",
        country: "País",
        currencyCode: "Código de moneda",
        date: "Fecha",
        distributorCnpj: "CNPJ del Distribuidor",
        distributorName: "Nombre del Distribuidor",
        distyCountryCode: "Código de país del distribuidor",
        endCountryCode: "Código de país del usuario final",
        endUser: "Usuario final",
        endUserInvoiceDate: "Fecha de la factura del usuario final",
        endUserNumber: "Número del usuario final",
        endUserOrder: "Pedido del usuario final",
        endUserVerticalMarket: "Mercado vertical del usuario final",
        extendedResale: "Precio de reventa extendido",
        flukeModelNoun: "Fluke Model Noum",
        invoiceDate: "Fecha de la factura",
        invoiceNumber: "Número de factura",
        partNumber: "Número de parte",
        postalCode: "Código postal",
        price: "Precio",
        provider: "Proveedor",
        providerId: "ID del proveedor",
        quantity: "Cantidad",
        resellerName: "Nombre del revendedor",
        shipmentDate: "Fecha de envío",
        sicCode: "Código SIC",
        state: "Estado (Usuario Final)",
        totalPice: "Precio total",
        unitResale: "Precio de reventa unitario",
        value: "Valor",
      },
      new: {
        subtitle: "Nuevo POS",
        message: {
          blankLines:
            "El archivo puede contener líneas en blanco o fuera del estándar arriba o debajo de la configuración.",
          headerConf:
            "Verifique si los encabezados de columna están definidos en la configuración:",
          headerFile:
            "Verifique si el archivo tiene solo una línea de encabezado",
          whatsHappening: "¿Qué podría estar sucediendo?",
        },
        columnsRelation: {
          definition: "Definición de columnas",
        },
        error: {
          inCityColumn:
            "La columna para 'CIUDAD' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          incnaeColumn:
            "La columna para 'ACTIVIDAD/cnae' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inDateColumn:
            "La columna para 'FECHA' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inDistributorIdColumn:
            "La columna para 'CRN DEL DISTRIBUIDOR' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inDistributorIdNumber:
            "La columna para 'CRN DEL DISTRIBUIDOR' tiene valor(es) inválido(s), estos datos deben ser un número de CNPJ válido.",
          inDistributorNameColumn:
            "La columna para 'NOMBRE DEL DISTRIBUIDOR' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inPartNumberColumn:
            "La columna para 'NÚMERO DE PARTE' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inProviderColumn:
            "La columna para 'PROVEEDOR' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inQuantityColumn:
            "La columna para 'CANTIDAD' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          inStateColumn:
            "La columna para 'ESTADO' tiene valor(es) faltante(s), y estos datos son obligatorios.",
          insufficientNumberOfColumns:
            "Archivo mal formateado o número insuficiente de columnas para continuar la operación.",
          invalidDateColumn:
            "La columna para 'FECHA' tiene valor(es) inválido(s), estos datos deben tener el siguiente formato: DÍA/MES/AÑO.",
          invalidPartNumberColumn:
            "La columna para 'NÚMERO DE PARTE' tiene valor(es) inválido(s), estos datos deben tener como máximo 8 caracteres.",
          invalidPartNumberType:
            "La columna para 'NÚMERO DE PARTE' tiene valor(es) inválido(s), estos datos deben contener solo números.",
          invalidQuantityColumn:
            "La columna para 'CANTIDAD' tiene valor(es) inválido(s), estos datos deben tener como máximo 4 dígitos.",
          requiredHeadersNotFoud:
            "Las columnas obligatorias definidas como estándar no se encontraron.",
          dataFail:
            "Hubo una falla desconocida, consulte las instrucciones al final de la página.",
        },
      },
      history: {
        fieldChange: "Cambio de campo",
        fullReplace: "Reemplazo completo",
      },
      view: {
        history: "Ver historial de ediciones",
        subtitle: "Vista de datos",
      },
      preview: {
        dataAnalyzed:
          'Datos analizados exitosamente. Por favor, presione el botón "FINALIZAR" para completar la operación.',
        hasUpdate:
          "Los elementos destacados en rojo tienen actualizaciones para valores ya ingresados (valores antiguos entre paréntesis).",
        whatDo: "¿Qué desea hacer?",
        error: {
          activityCnae: "El campo CNAE de Actividad es obligatorio.",
          branchId: "El campo ID de Sucursal es obligatorio.",
          city: "El campo Ciudad es obligatorio.",
          country: "El campo País es obligatorio.",
          currencyCode: "El campo Código de Moneda es obligatorio.",
          distributorCnpj:
            "El campo CNPJ del Distribuidor es obligatorio y debe ser un CNPJ válido.",
          distributorName: "El campo Nombre del Distribuidor es obligatorio.",
          distyCountryCode:
            "El campo Código del País del Distribuidor es obligatorio.",
          endCountryCode: "El campo Código del País Final es obligatorio.",
          endUser: "El campo Usuario Final es obligatorio.",
          endUserInvoiceDate:
            "El campo Fecha de Factura del Usuario Final es obligatorio.",
          endUserNumber: "El campo Número del Usuario Final es obligatorio.",
          endUserOrder: "El campo Pedido del Usuario Final es obligatorio.",
          endUserVerticalMarket:
            "El campo Mercado Vertical del Usuario Final es obligatorio.",
          extendedResale:
            "El campo Reventa Extendida es obligatorio y debe ser un número.",
          flukeModelNoun: "El campo Nombre del Modelo Fluke es obligatorio.",
          invoiceDate:
            "El campo Fecha de Factura es obligatorio y tiene valores inválidos o ausentes.",
          invoiceNumber: "El campo Número de Factura es obligatorio.",
          partNumber: "El campo Número de Pieza es obligatorio.",
          postalCode: "El campo Código Postal es obligatorio.",
          price: "El campo Precio es obligatorio y debe ser un número.",
          provider: "El campo Proveedor es obligatorio.",
          quantity: "El campo Cantidad es obligatorio y debe ser un número.",
          resellerName: "El campo Nombre del Revendedor es obligatorio.",
          shipmentDate:
            "El campo Fecha de Envío es obligatorio y debe ser una fecha válida.",
          sicCode: "El campo Código SIC es obligatorio.",
          state: "El campo Estado es obligatorio.",
          unitResale:
            "El campo Reventa Unitaria es obligatorio y debe ser un número.",
        },
      },
      missingData: {
        dateTotalValue:
          "Por favor, reporte las fechas y los totales correspondientes:",
        informTotalValue:
          "Por favor, indique el <strong>VALOR TOTAL</strong> para las siguientes fechas:",
        subtitle: "Datos faltantes",
      },
      setDefaults: {
        firstTime:
          "Dado que esta es la primera vez que se realiza una carga de archivo, los siguientes elementos deben configurarse: ",
        subtitle: "Configuración de datos",
      },
      withoutSales: {
        setPerdiod: "Establecer periodo",
        subtitle: "Periodo sin ventas",
      },
    },
    login: {
      forgotPassword: "¿Olvidaste tu contraseña?",
      formEmail: "Email",
      formPassword: "Contraseña",
      loginFail: "Nombre de usuario o contraseña incorrectos",
      title: "Portal de distribuidores",
    },
    forgot: {
      disclaimer:
        "Se ha enviado un correo electrónico con instrucciones para restablecer la contraseña.",
      title: "Recuperar contraseña",
    },
    reset: {
      title: "Restablecer contraseña",
    },
    users: {
      title: "Usuarios",
      subtitle: "Lista",
      permissions: {
        approvals: "Aprobaciones",
        contact: "Contacto",
        delete: "Eliminar",
        distributors: "Distribuidores",
        inventory: "Inventario",
        mkt: "Marketing",
        mktsite: "Sitio de Marketing",
        orders: "Pedidos",
        pos: "POS",
        settings: "Configuraciones",
        users: "Usuarios",
      },
      list: {
        createdAt: "Creado en",
        email: "Email",
      },
      edit: {
        subtitle: "Editar usuario",
      },
      new: {
        subtitle: "Nuevo usuario",
      },
    },
    setPassword: {
      checkTerms: "He leído y acepto los términos de compromiso.",
      disclaimer: "Esta CONTRASEÑA es TEMPORAL.",
      disclaimerCompliance:
        "Debe aceptar los términos de responsabilidad para usar el sistema.",
      openCompliance: "Abrir términos de compromiso",
      termResponsibility:
        "<p> Yo, como distribuidor de Fluke y usuario del Portal de Distribuidores, confirmo que: </p> <ul> <li> Estoy al tanto de las disposiciones de la Ley General de Protección de Datos (Ley nº 13.709/2018); </li> <li> Acepto la Política de Privacidad de Fluke y del Portal de Distribuidores; </li> <li> No puedo compartir datos personales de clientes de Fluke y clientes potenciales con terceros sin su autorización expresa; </li> <li> Los datos personales que los clientes y clientes potenciales compartieron a través del Portal tienen su consentimiento expreso para el intercambio de sus datos con Fluke; </li> <li> todos los ítems anteriores son igualmente aplicables a cualquier tercero, colaboradores y socios que hagan uso de las credenciales de acceso al portal proporcionadas por mí; </li> <li> El incumplimiento de cualquiera de estos ítems resultará en la revocación del acceso del distribuidor al Portal y otras medidas administrativas aplicables. </li> </ul>",
      title: "Establecer contraseña",
      titleCompliance: "ES - Términos de responsabilidad",
    },
    profile: {
      personalData: "Datos personales",
      resetPass: "Restablecer contraseña",
      subtitleSetPassword: "Cambiar contraseña",
      title: "Mi cuenta",
    },
    settings: {
      title: "Configuraciones",
      subtitle: {
        activeCnae: "cnae activo",
        boxAuth: "Box Auth",
        bulkUpload: "Carga masiva",
        countries: "Países",
        fileHeaders: "Encabezados de archivo",
        jobroles: "Roles de trabajo",
        keywords: "Palabras clave",
        mktContactEmails: "Contacto de Marketing - Emails dos responsáveis",
        mktOrdersEmails: "Pedidos de Marketing - Emails dos responsáveis",
        mktSupportEmails: "Soporte de Marketing - Emails dos responsáveis",
        paymentMethods: "Métodos de pago",
        purchaseIntervals: "Intervalos de compra",
        verticalActivityRel: "Relación de actividad vertical",
      },
      new: {
        subtitle: "Nuevo método de pago",
      },
      country: {
        subtitle: "Países",
        list: {
          name: "Nombre",
          createdAt: "Creado en",
        },
      },
      payment: {
        subtitle: "Métodos de pago",
        list: {
          description: "Descripción",
          createdAt: "Creado en",
        },
      },
      fileHeaders: {
        productsInOrders: "Archivo de productos dentro de pedidos",
        orders: "Archivo de pedidos",
        pos: "Archivo POS",
      },
      verticalActivityRel: {
        help: {
          keywords:
            "A continuación, puedes ver las palabras clave registradas y sus respectivas verticales y crear una nueva palabra clave si es necesario.",
          keywordsNew:
            'La clasificación se realiza mediante comparación parcial de palabras. <br /> Ten cuidado al ingresar palabras con pocas letras o genéricas.<br /><br /> Ejemplo:<br /> Si registras la palabra clave "Ci" con "Cemento" y sus variaciones en mente para clasificación automática en la vertical "Cemento", "Cine" o cualquier actividad que tenga "Ci" en su composición, se clasificará automáticamente en la vertical "Cemento".',
          manageActiveCnae:
            "¡Hola! Este es un espacio para gestionar las actividades/cnaes que son reportadas por los distribuidores y sus respectivas verticales. Estas verticales se visualizan en el informe de Power BI POS, así que presta atención para realizar las clasificaciones correctamente.<br /> Aquí puedes ver las actividades que no fueron clasificadas automáticamente, es decir, que deben clasificarse individualmente. También puedes ver todas las actividades registradas y hacer cambios si es necesario.",
          bulkUpload:
            'Puedes realizar una carga masiva de actividades y clasificación vertical. Ten en cuenta que, si existe una actividad previamente clasificada en tu nuevo archivo, la información se reemplazará.<br /><br /> Ejemplo: "Actividades comerciales en general" fue clasificada anteriormente en la vertical "Comercio", pero en este nuevo archivo se clasifica como "Otros" - En ciertos casos, la información podrá sobrescribirse y esta actividad pasará a formar parte del vertical “Otros”.',
        },
        subtitle: {
          activity: "Actividades - Relación Vertical",
          allActivities: "Todas las actividades y cnaes clasificados",
          configRelation: "Configuración: Relación Actividades-Vertical",
          relationActivities: "Relacionar actividades con verticales",
        },
        message: {
          columnRelation:
            "Relaciona las columnas del archivo con las del sistema.",
          verticalConflict:
            "Conflicto entre verticales. [Antiguo: %{old}, Nuevo: %{new} ]",
          recentFileSend:
            "Se ha enviado un archivo recientemente y sus datos aún no están disponibles, vuelve a intentarlo en unos minutos.",
          notAbleToClassify:
            "No se pudo clasificar este término automáticamente.",
        },
      },

      jobrole: {
        list: {
          role: "Rol de trabajo",
          createdAt: "Creado en",
        },
      },
    },
  },
  labels: {
    action: "Acción",
    active: "Activo",
    activeCnae: "cnae Activo (Usuario Final)",
    activeCnaeMenu: "Activo / cnae",
    activeCompanyId: "CRN Activo (Usuario Final)",
    activities: "Actividades",
    activityWithoutClassification: "Actividad sin clasificación",
    biling: "Facturación",
    btnAdd: "Agregar",
    btnBack: "Volver",
    btnBoxAuth: "Autenticar aplicación",
    btnCancel: "Cancelar",
    btnClean: "Limpiar",
    btnClearForm: "Limpiar formulario",
    btnClose: "Cerrar",
    btnConfirmData: "Confirmar datos",
    btnContinue: "Continuar",
    btnDelete: "Eliminar",
    btnDeleteOrder: "Eliminar pedido",
    btnDeletePOS: "Eliminar POS",
    btnDownload: "Descargar",
    btnEdit: "Editar",
    btnEvaluate: "Evaluar términos nuevamente",
    btnFileProcess: "Procesar archivo",
    btnFinish: "Finalizar",
    btnGenerateNew: "Generar nuevo",
    btnGeneratePdf: "Generar PDF",
    btnGoHome: "Ir a Home Marketing",
    btnLogin: "Iniciar sesión",
    btnLogout: "Cerrar sesión",
    btnManage: "Gestionar",
    btnNemItem: "Nuevo ítem",
    btnNew: "Nuevo",
    btnNewSend: "Nuevo envío",
    btnNewTicket: "Nuevo ticket",
    btnProcessData: "Procesar datos",
    btnRedefine: "Redefinir",
    btnRemoveInventory: "Eliminar inventario",
    btnReport: "Informe",
    btnReset: "Restablecer",
    btnSave: "Guardar",
    btnSaveDraft: "Guardar como borrador",
    btnSaving: "Guardando",
    btnSeeActive: "Ver todos los activos",
    btnSelectFile: "Seleccionar archivo",
    btnSend: "Enviar",
    bulkUpload: "Carga masiva",
    cancel: "Cancelar",
    change: "Cambiar",
    city: "Ciudad (Usuario Final)",
    clientWithdraws: "Retiros del cliente",
    cnae: "cnae",
    companyId: "CRN",
    contract: "Contrato",
    countries: "Países",
    countryEn: "País (EN)",
    countryEs: "País (ES)",
    countryPt: "País (PT)",
    createdAt: "Creado en",
    createEdit: "Crear/Editar",
    currencyPattern:
      "Patrón para valores monetarios (separadores decimales y de miles)",
    data: "Datos",
    dataInsert: "Insertar fecha",
    dataProgram: "Fecha programada",
    date: "Fecha",
    datePattern: "Patrón de fecha",
    description: "Descripción",
    descriptionEn: "Descripción (EN)",
    descriptionEs: "Descripción (ES)",
    descriptionPt: "Descripción (PT)",
    distributor: "Distribuidor",
    distributorCompanyId: "CRN del Distribuidor",
    distributorName: "Nombre del Distribuidor",
    editedItem: "Ítem editado",
    end: "Fin",
    endUser: "Usuario Final",
    error: "Error",
    file: "Archivo",
    files: "Archivos",
    finalCustomer: "Cliente final",
    formConfirmPassword: "Confirmar contraseña",
    formEmail: "Correo electrónico",
    formName: "Nombre",
    formNewPassword: "Nueva contraseña",
    formPassword: "Contraseña",
    funnel: "Embudo",
    hint: "Sugerencia",
    hintSizeSugested: "Tamaño sugerido %{size} píxeles",
    ignore: "Ignorar",
    insertManualProduct: "Insertar producto manualmente",
    inventoryDate: "Fecha de inventario",
    keywords: "Palabras clave",
    language: "Idioma",
    model: "Modelo",
    month: "Mes",
    name: "Nombre",
    new: "Nuevo",
    newLine: "Nueva línea",
    no: "No",
    noRelation: "Sin relación",
    notRequiredLabel: "No requerido",
    notSend: "No enviado",
    number: "Número",
    observation: "Observación",
    onStock: "En stock",
    or: "O",
    order: "Pedido",
    orderNumber: "Número de pedido *",
    orgSearch: "Organización/Búsqueda",
    outStock: "Fuera de stock",
    partialPayment: "Facturación parcial",
    partNumber: "Part number/EAN code",
    paymentCondition: "Condición de pago",
    periodWithoutSales: "Período sin ventas",
    permissions: "Permisos",
    personalData: "Datos personales",
    price: "Precio",
    processFile: "Procesar archivo",
    processingFile: "Procesando archivo",
    productLink: "Enlace del producto",
    products: "Productos",
    purchasePrice: "Precio de compra",
    qtd: "Cantidad",
    read: "Leer",
    redefinePassword: "Redefinir contraseña",
    remove: "Eliminar",
    replace: "Reemplazar",
    role: "Rol",
    roleEn: "Rol (EN)",
    roleEs: "Rol (ES)",
    rolePt: "Rol (PT)",
    segment: "Segmento",
    select: "Seleccionar...",
    selectColumn: "Seleccionar una columna del archivo",
    selectCountry: "Seleccionar un país",
    selectFile: "Seleccionar un archivo",
    selectMonth: "Seleccionar un mes",
    selectVertical: "Seleccionar un vertical",
    selectYear: "Seleccionar un año",
    sendFile: "Enviar archivo",
    sendXls: "Enviar XLS/XLSX",
    shipCompany: "Empresa de envío",
    start: "Inicio",
    state: "Estado (Usuario Final)",
    supplier: "Proveedor",
    supplierId: "ID del Proveedor",
    system: "Sistema",
    terms: "Términos",
    title: "Título",
    totalLockAlertContent: "El archivo ya tiene todos los precios y el valor total se calcula automáticamente.",
    totalLockAlertTitle: "¡Cambio no disponible!",
    totalPriceAlertContent: "¡Cambiar estos datos cambiará automáticamente el valor total del documento! ¿Desea continuar?",
    totalPriceAlertTitle: "Atención!",
    totalValue: "Valor total",
    type: "Tipo",
    typeShipping: "Tipo de envío",
    unitPrice: "Precio de venta unitario bruto reportado",
    unitValue: "Valor unitario (sin impuestos)",
    uploading: "Cargando",
    user: "Usuario",
    value: "Valor",
    vertical: "Vertical",
    warning: "Advertencia",
    withoutSales: "Periodo vacío o sin ventas",
    yes: "Sí",
    yourPassword: "Tu contraseña",
  },
  snackbar: {
    dataAlterSuccess: "Datos alterados exitosamente",
    draftAterSuccess: "Borrador alterado exitosamente",
    editCountry: "País editado exitosamente",
    editJobRole: "Rol de trabajo editado exitosamente",
    editPaymentMethod: "Método de pago editado exitosamente",
    fileHeadersInventorySuccess:
      "Encabezados de archivo para inventario guardados exitosamente",
    fileHeadersOrderSuccess:
      "Encabezados de archivo para pedidos guardados exitosamente",
    fileHeadersPosSuccess:
      "Encabezados de archivo para POS guardados exitosamente",
    malFormFile:
      "El archivo está mal formateado o tiene un número insuficiente de columnas para continuar la operación.",
    orderAlterSuccess: "Pedido alterado exitosamente",
    passwdAlterSuccess: "Contraseña alterada exitosamente",
    posErrorColumn:
      "Por favor, seleccione otro valor para la columna %{column}, este valor no es válido.",
    posNoSellsReportSuccess: "POS insertado exitosamente",
    posSetDefaultError:
      "¡Ocurrió un error! Por favor, verifique si los datos fueron completados correctamente.",
    saveCountry: "País guardado exitosamente",
    saveJobRole: "Rol de trabajo guardado exitosamente",
    saveMktSupportEmail:
      "Correo electrónico de soporte de marketing guardado exitosamente",
    savePaymentMethod: "Método de pago guardado exitosamente",
  },
  alerts: {
    pendency: "Hay <strong>%{total}</strong> pendientes activos",
    generateNewToken:
      "¿Desea restablecer este token? La integración con el sistema dejará de funcionar hasta que se actualice en Microsiga.",
    inventoryRemove: "¿Desea eliminar estos datos?",
    posInsertValues: "Por favor inserte los valores correctos.",
    posNoSellsReportAlreadyExists:
      "¡Esta fecha ya ha sido insertada! ¿Desea reemplazarla?",
    posPreviewAlreadyExists:
      "Este archivo contiene datos ya registrados. ¿Desea reemplazar estos datos?",
    posRemove: "¿Desea eliminar estos datos?",
    removeCompanyId: "¿Desea eliminar este CRN?",
    removeContract: "¿Desea eliminar este contrato?",
    removeTag: "¿Desea eliminar esta etiqueta?",
    successCompanyRemove: "¡CRN eliminado con éxito!",
    successRemoveContract: "¡Contrato eliminado con éxito!",
    userEmailExists: "Email ya registrado. Utilice otro email.",
    userRemove: "¿Desea eliminar a este usuario?",
    userSuccess: "¡Usuario creado exitosamente!",
    userSuccessEdit: "¡Usuario editado exitosamente!",
  },
  menu: {
    approvals: "Aprobaciones",
    contact: "Contacto",
    distributors: "Distribuidores",
    inventory: "Inventario",
    marketing: "Marketing",
    orders: "Pedidos",
    pos: "POS",
    settings: "Configuraciones",
    users: "Usuarios",
    children: {
      itens: "Ítems",
      languages: "Idiomas",
      list: "Lista",
      new: "Nuevo",
      newsletter: "Boletín",
      portal: "Portal de inicio",
      suport: "Soporte",
      tags: "Etiquetas",
    },
  },
  validation: {
    confirmPassword: "Las contraseñas no coinciden",
    email: "El correo electrónico no es válido",
    invalidCNPJ: "Por favor, ingrese un CNPJ válido",
    invalidDate: "Por favor, ingrese una fecha válida",
    invalidNumber: "Por favor, ingrese un número válido",
    posNotRequired:
      'Datos no requeridos, si no los tiene, seleccione la opción "no relacionado".',
    required: "Este campo es obligatorio",
    validateCnpj: "Este campo es obligatorio y debe ser un CNPJ válido.",
    validateDate: "Este campo es obligatorio y debe ser una fecha válida.",
    validateNumber: "Este campo es obligatorio y debe ser un número válido.",
    mustBeNumeric: "Este campo debe ser numérico",
  },
};
